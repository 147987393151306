import { Link } from "gatsby"
import * as React from "react"

import GatsbyLink from "gatsby-link"
import styled from "styled-components"
import { BackgroundImage } from "../components/Images"
import { LogoHeading } from "../components/Layout/Heading"
import Layout, { Container } from "../components/Layout/Layout"
import SEO from "../components/Layout/Seo"

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: gray;
  font-size: 12px;
`

export default class BlogListPage extends React.Component<{ pageContext }> {
  render() {
    const { pageContext } = this.props

    let blogElements = pageContext.group.map((node, idx) => {
      // markdown node
      let imgComponent = <div />
      try {
        const imgPath: string = node.frontmatter.image
        if (imgPath) {
          const ext = imgPath.substr(imgPath.length - 4, 4)
          const withoutExt = imgPath.substr(0, imgPath.length - 4)
          let thumbnailPath = withoutExt + "-150x150" + ext
          let src = "/" + thumbnailPath
          if (src) {
            imgComponent = (
              <img
                style={{ width: "7rem", float: "right", margin: "10px" }}
                src={src}
              />
            )
          }
        }
        //  else {
        //   if (node.frontmatter.images && node.frontmatter.images.length > 0) {
        //     let imgSrc = node.frontmatter.images[0]
        //     imgComponent = (
        //       <img
        //         style={{ width: "7rem", float: "right", margin: "10px" }}
        //         src={imgSrc}
        //       />
        //     )
        //   }
        // }
      } catch (error) {
        console.log(error)
      }
      let blogPostPath = node.frontmatter.path

      return (
        <BlogItem
          key={idx}
          title={node.frontmatter.title}
          path={blogPostPath}
          imgComponent={imgComponent}
          date={node.frontmatter.date}
          excerpt={node.excerpt}
        />
      )
    })

    return (
      <Layout>
        <SEO title={"Archive"} />
        <BackgroundImage />
        <LogoHeading />
        <Container>
          <h1>Archive</h1>
          <p>A collection of blog posts from our old site.</p>
          {blogElements}
          <FlexDiv>
            <FooterLink
              to={`/blog/${pageContext.index + 1}`}
              disabled={pageContext.last}
            >
              {"« Older posts"}
            </FooterLink>
            {`${pageContext.index} / ${pageContext.pageCount}`}
            <FooterLink
              disabled={pageContext.first}
              to={`/blog/${
                pageContext.index - 1 === 1 ? "" : pageContext.index - 1
              }`}
            >
              {"Newer posts »"}
            </FooterLink>
          </FlexDiv>
        </Container>
      </Layout>
    )
  }
}

function BlogItem({ path, title, imgComponent, date, excerpt }) {
  return (
    <div style={{ marginBottom: "0", overflow: "auto" }}>
      <Header2Link to={path}>{title}</Header2Link>
      <Link to={path}>{imgComponent}</Link>
      <h6>{date}</h6>
      <p>{excerpt}</p>
    </div>
  )
}

function Header2Link({ children, to = "/", ...props }) {
  return (
    <h2>
      <StyledLink to={to} replace={false} {...props}>
        {children}
      </StyledLink>
    </h2>
  )
}
const StyledLink = styled(GatsbyLink)`
  &:hover,
  &:focus {
    /* text-decoration: none; */
    outline: 0;
    color: inherit;
  }
  color: inherit;
  appearance: none;
`

const FooterLink = styled(Link)<{ disabled?: boolean }>`
  appearance: none;
  padding: 8px;
  font-size: 1rem;
  &:hover,
  &:focus {
    outline: 0;
    color: rebeccapurple;
  }
  pointer-events: ${props => (props.disabled ? "none" : "inherit")};
  color: ${props => (props.disabled ? "gray" : "purple")};
`
